/** @format */

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: (resolve) => require(['@/pages/Home'], resolve),
    meta: {
      title: '首页',
      keepAlive: true,
    },
  },
  {
    path: '/message',
    name: 'message',
    component: (resolve) => require(['@/pages/Message'], resolve),
    meta: {
      title: '消息',
      back: true,
    },
  },
  {
    path: '/messageDetail',
    name: 'messageDetail',
    component: (resolve) => require(['@/pages/MessageDetail'], resolve),
    meta: {
      title: '消息详情',
      back: true,
    },
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: (resolve) => require(['@/pages/RankingList'], resolve),
    meta: {
      title: '积分排行榜',
      back: true,
    },
  },
  {
    path: '/rankingDetail',
    name: 'rankingDetail',
    component: (resolve) => require(['@/pages/RankingDetail'], resolve),
    meta: {
      title: '积分排行榜',
      back: true,
    },
  },
  {
    path: '/user',
    name: 'user',
    component: (resolve) => require(['@/pages/User'], resolve),
    meta: {
      title: '我的',
      back: true,
    },
  },
  {
    path: '/space',
    name: 'UserSpace',
    component: (resolve) => require(['@/pages/UserSpace'], resolve),
    meta: {
      title: '个人中心',
      back: true,
    },
  },
  {
    path: '/records',
    name: 'UserRecords',
    component: (resolve) => require(['@/pages/UserRecords'], resolve),
    meta: {
      title: '我的打卡',
      back: true,
    },
  },
  {
    path: '/recordsDetail',
    name: 'UserRecordsDetail',
    component: (resolve) => require(['@/pages/UserRecordsDetail'], resolve),
    meta: {
      title: '动态详情',
      back: true,
    },
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: (resolve) => require(['@/pages/Wallet'], resolve),
    meta: {
      title: '我的积分',
      back: true,
    },
  },
  {
    path: '/shop',
    name: 'Shop',
    component: (resolve) => require(['@/pages/Shop'], resolve),
    meta: {
      title: '积分商场',
      back: true,
    },
  },
  {
    path: '/getPrize',
    name: 'GetPrize',
    component: (resolve) => require(['@/pages/GetPrize'], resolve),
    meta: {
      title: '中奖记录',
      back: true,
    },
  },
  {
    path: '/payOrder',
    name: 'PayOrder',
    component: (resolve) => require(['@/pages/PayOrder'], resolve),
    meta: {
      title: '领取奖品',
      back: true,
    },
  },
  {
    path: '/idea',
    name: 'Idea',
    component: (resolve) => require(['@/pages/Idea'], resolve),
    meta: {
      title: '我的金点子',
      back: true,
    },
  },
  {
    path: '/ideaDetail',
    name: 'IdeaDetail',
    component: (resolve) => require(['@/pages/IdeaDetail'], resolve),
    meta: {
      title: '金点子',
      back: true,
    },
  },
  {
    path: '/userPrize',
    name: 'UserPrize',
    component: (resolve) => require(['@/pages/UserPrize'], resolve),
    meta: {
      title: '我的奖品',
      back: true,
    },
  },
  {
    path: '/config',
    name: 'Config',
    component: (resolve) => require(['@/pages/Config'], resolve),
    meta: {
      title: '设置',
      back: true,
    },
  },
  {
    path: '/feedback',
    name: 'FeedBack',
    component: (resolve) => require(['@/pages/FeedBack'], resolve),
    meta: {
      title: '意见反馈',
      back: true,
    },
  },
  {
    path: '/richText',
    name: 'RichText',
    component: (resolve) => require(['@/pages/RichText'], resolve),
    meta: {
      title: '用户服务协议',
      back: true,
    },
  },
  {
    path: '/inspect',
    name: 'Inspect',
    component: (resolve) => require(['@/pages/Inspect'], resolve),
    meta: {
      title: '我的检查',
      back: true,
    },
  },
  {
    path: '/inspectDetail',
    name: 'InspectDetail',
    component: (resolve) => require(['@/pages/InspectDetail'], resolve),
    meta: {
      title: '安全检查',
      back: true,
    },
  },
  {
    path: '/rectify',
    name: 'Rectify',
    component: (resolve) => require(['@/pages/Rectify'], resolve),
    meta: {
      title: '我的整改',
      back: true,
    },
  },
  {
    path: '/rectifyDetail',
    name: 'RectifyDetail',
    component: (resolve) => require(['@/pages/RectifyDetail'], resolve),
    meta: {
      title: '我的整改详情',
      back: true,
    },
  },

  // 罗
  {
    path: '/clockInRing',
    name: 'clockInRing',
    component: (resolve) => require(['@/pages/ClockInRing'], resolve),
    meta: {
      title: '打卡圈',
      back: true,
      keepAlive: true,
    },
  },
  {
    path: '/CheckIn1',
    name: 'CheckIn1',
    component: (resolve) => require(['@/pages/CheckIn/CheckIn1'], resolve),
    meta: {
      title: '安全执行',
      back: true,
    },
  },
  {
    path: '/CheckIn2',
    name: 'CheckIn2',
    component: (resolve) => require(['@/pages/CheckIn/CheckIn2'], resolve),
    meta: {
      title: '安全观察',
      back: true,
    },
  },
  {
    path: '/CheckIn3',
    name: 'CheckIn3',
    component: (resolve) => require(['@/pages/CheckIn/CheckIn3'], resolve),
    meta: {
      title: '安全学习',
      back: true,
    },
  },
  {
    path: '/CheckIn3Detail',
    name: 'CheckIn3Detail',
    component: (resolve) =>
      require(['@/pages/CheckIn/CheckIn3Detail/CheckIn3Detail'], resolve),
    meta: {
      title: '安全学习',
      back: true,
    },
  },
  {
    path: '/CheckIn3Detail1',
    name: 'CheckIn3Detail1',
    component: (resolve) =>
      require(['@/pages/CheckIn/CheckIn3Detail/CheckIn3Detail1'], resolve),
    meta: {
      title: '安全学习',
      back: true,
    },
  },
  {
    path: '/CheckIn3DetailResult',
    name: 'CheckIn3DetailResult',
    component: (resolve) =>
      require(['@/pages/CheckIn/CheckIn3Detail/CheckIn3DetailResult'], resolve),
    meta: {
      title: '考试结果',
      back: true,
    },
  },
  {
    path: '/Eliminate',
    name: 'Eliminate',
    component: (resolve) => require(['@/pages/Eliminate/Eliminate'], resolve),
    meta: {
      title: '安全隐患消除',
      back: true,
    },
  },
  {
    path: '/hiddenDangerReporting',
    name: 'hiddenDangerReporting',
    component: (resolve) =>
      require(['@/pages/hiddenDanger/hiddenDangerReporting'], resolve),
    meta: {
      title: '安全隐患上报',
      back: true,
    },
  },
  {
    path: '/safetyInspection',
    name: 'safetyInspection',
    component: (resolve) =>
      require(['@/pages/hiddenDanger/safetyInspection'], resolve),
    meta: {
      title: '安全检查',
      back: true,
    },
  },
  {
    path: '/safetyInspectionDetail',
    name: 'safetyInspectionDetail',
    component: (resolve) =>
      require(['@/pages/hiddenDanger/safetyInspectionDetail'], resolve),
    meta: {
      title: '提交成功',
      back: true,
    },
  },
  {
    path: '/goldenPoint',
    name: 'goldenPoint',
    component: (resolve) =>
      require(['@/pages/hiddenDanger/goldenPoint'], resolve),
    meta: {
      title: '金点子',
      back: true,
    },
  },
  {
    path: '/goldenPointDetail',
    name: 'goldenPointDetail',
    component: (resolve) =>
      require(['@/pages/hiddenDanger/goldenPointDetail'], resolve),
    meta: {
      title: '上传结果',
      back: true,
    },
  },
  {
    path: '/CheckIn4',
    name: 'CheckIn4',
    component: (resolve) => require(['@/pages/CheckIn/CheckIn4'], resolve),
    meta: {
      title: '安全十六条',
      back: true,
    },
  },
  {
    path: '/CheckIn4Detail',
    name: 'CheckIn4Detail',
    component: (resolve) =>
      require(['@/pages/CheckIn/CheckIn4Detail/CheckIn4Detail'], resolve),
    meta: {
      title: '安全十六条',
      back: true,
    },
  },
  {
    path: '/ShareCheckIn',
    name: 'ShareCheckIn',
    component: (resolve) => require(['@/pages/ShareCheckIn'], resolve),
    meta: {
      title: '安全十六条',
      back: true,
    },
  },
]

const router = new VueRouter({
  routes,
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '默认标题'
  const { state, success } = store.getters['clockIn/getMark']
  if (
    state &&
    (from.name == 'CheckIn4Detail' || from.name == 'CheckIn3Detail1')
  ) {
    return success ? success() : undefined
  }
  next()
})
export default router
