/** @format */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import 'amfe-flexible'
import 'vant/lib/index.css'
import { components } from '@/plugin/vant'
import commonComponents from '@/plugin/commonComponents'
import Vconsole from 'vconsole'

let vConsole = new Vconsole()
Vue.use(vConsole)
// 按需注册ui库组件
Object.entries(components).forEach((v) => Vue.use(v[1]))
Vue.use(commonComponents)
Vue.config.productionTip = false
Vue.prototype.router = router
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
