<script>
export default {
  name: 'HomeHeader',
  // props: {
  //   noBack: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  data() {
    return {
      back: true,
      title: undefined,
    }
  },
  watch: {
    $route: {
      handler(newVal) {
        this.title = newVal.meta.title ?? ''
        this.back = newVal.meta.back ?? false
      },
      deep: true,
    },
  },
  methods: {
    goBack() {
      this.$router.back()
    },
  },
}
</script>

<template>
  <div class="qz-header">
    <div v-if="back" class="qz-go-back" @click="goBack"></div>
    <div class="qz-title">
      {{ title }}
    </div>
  </div>
</template>

<style lang="less" scoped>
.qz-header {
  background: #3875c6;
  height: 124px;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  color: #ffffff;
  font-family: 'Source Han Sans CN';
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  .qz-go-back {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #ffffff33;
    position: relative;
    margin-right: 32px;
  }
  .qz-go-back::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    left: 22px;
    top: 50%;
    background: #fff;
    transform: translateY(-50%) rotateZ(45deg);
    transform-origin: left;
  }

  .qz-go-back::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    left: 22px;
    top: 50%;
    background: #fff;
    transform: translateY(-50%) rotateZ(-45deg);
    transform-origin: left;
  }
}
</style>
