export default {
    getUserInfo(state) {
        return state.userInfo
    },
    getAddressInfo(state) {
        return state.addressInfo
    },
    getAddressMarker(state) {
        return state.addressInfo.marker
    },
    getMessage(state) {
        return state.message
    },
    getAgreement(state) {
        return state.agreement
    },
}