export default {
  getMessageDetail(state) {
    return state.messageDetail
  },
  getRakingList(state) {
    return state.rakingList[state.rakingList.active]
  },
  getRakingKey(state) {
    return state.rakingList.active
  },
  getRakingDetail(state) {
    return state.rakingDetail
  },
  getPrizeDetail(state) {
    return state.prizeDetail
  },
  getAreaslist(state) {
    return state.areaslist
  },
  getIdeaDetail(state) {
    return state.ideaDetail
  },
  getInspectDetail(state) {
    return state.inspectDetail
  },
  getRectifyDetail(state) {
    return state.rectifyDetail
  },
  getAddressInfo(state) {
    return state.addressInfo
  },
  getAddressMarker(state) {
    return state.addressInfo.marker
  },
  getRegionList(state) {
    return state.region
  },
}
