/** @format */

// import {
//     ElLoading,
//     ElMessage,
// } from 'element-plus';
// import router from '@/router/index.js'

// import SYS_CONST from './constant';
import store from '../store/index.js'
import { Toast } from 'vant'
/**
 * HOST   请求地址
 * HOST_FILE_UP   文件请求地址
 */
const ENV = process.env.NODE_ENV

// // const HOST_FILE_UP: string | undefined = process.env.VUE_APP_IMG_UP_HOST;
// export const HOST = ENV == 'production' ? process.env.VUE_APP_HOST : '/api'
export const HOST =
  ENV == 'production' ? window.location.origin + '/cole-safe-uat/api' : '/api'
// const HOST_FILE_UP: string | undefined = process.env.VUE_APP_IMG_UP_HOST;

/**
 *  这部分是为了完成请求的加载中状态 和 超时控制
 * loading {ILoadingInstance} 接受elment loading 弹框提示的实例
 * overtime  设置请求超时时长
 * noTokenList  不需要token的请求路径
 */
let loading
const overtime = 1000 * 60 * 5
const downLoadFileOvertime = 1000 * 60 * 30

const noTokenList = ['login']
let dsq

/**
 * fetch init通用参数
 */
// interface defaultParamsParams {
//     mode?: "same-origin" | "cors" | "navigate" | "no-cors" | undefined;
//     cache?: "default" | "force-cache" | "no-cache" | "no-store" | "only-if-cached" | "reload" | undefined,
//     credentials?: "include" | "omit" | "same-origin" | undefined;
//     redirect?: "error" | "follow" | "manual" | undefined,
//     referrer?: "client" | "no-referrer" | undefined,
//     [propName: string]: any;
// }

const defaultParams = {
  mode: 'cors',
  credentials: 'include',
}

/**
 * 设置通用请求头部  暂时没有去找 headers 中的参数以及参数类型 所以暂写两项类型为any
 * @param  {string} url  请求url
 */
// interface headerParams {
//     Accept?: any;
//     'Content-Type'?: any;
//     [propName: string]: any;
// }

function initHeader(url, upLoad = false) {
  let headers = {
    Accept: 'application/json',
  }
  if (!noTokenList.find((v) => v === url)) {
    // const token = sessionStorage.getItem(SYS_CONST.STORAGE_KEY.USER_TOKEN);
    // if (token) headers.Authorization = token;
  }
  if (!upLoad) headers['Content-Type'] = 'application/json;charset=utf-8'
  // upLoad
  return headers
}

/**
 * 创建基本的fetch请求
 * @param  {string} method  请求类型
 * @param  {object} header  额外的请求头
 * @param  {string} url  请求url
 * @param  {any} body  请求体
 * @return {Promise<any>}
 * controller  AbortController 接口表示一个控制器对象，允许你根据需要中止一个或多个 Web请求。
 * signal
 */
// interface createFetchParams {
//     method: string,
//     url: string,
//     header?: {},
//     body?: any,
//     upLoad?: boolean,
// }

export function createFetch(param, _overtime = undefined) {
  const { method, url, body, header = {}, upLoad } = param
  // loading = ElLoading.service({
  //     lock: false,
  //     text: '加载中',
  //     spinner: 'el-icon-loading',
  //     background: 'rgba(0, 0, 0, 0.7)'
  // });

  const controller = new AbortController()
  const signal = controller.signal

  return Promise.race([
    new Promise((resolve, reject) => {
      fetch(HOST + url, {
        ...defaultParams,
        headers: {
          ...initHeader(url, upLoad),
          ...header,
        },
        method,
        signal,
        body: upLoad ? body : JSON.stringify(body),
      })
        .then(responseHandle)
        .then(successHandle(url, resolve, reject))
        .catch(errorHandle(url, reject))
      // .finally(() => loading!.close())   // 这一行打开 则不管请求成功与否 加载效果都会被关闭  暂且关上
    }),
    new Promise(
      () =>
        (dsq = setTimeout(
          () => controller.abort(),
          _overtime ? _overtime : overtime
        ))
    ),
  ])
}

/**
 * 请求响应/获取完整响应信息处理
 * @param  {Response} response  // 原始响应头
 * @return {Promise}
 */
// interface successResponse extends Response {
//     code: number;
//     data: {};
//     msg?: string;
//     error?: string;
// }

function responseHandle(response) {
  const { status } = response
  if (dsq) clearTimeout(dsq)
  // console.log('status', status, router)
  if (status === 403) {
    // loading!.close();
    // 这里给个弹窗
    // router.replace('/login')
    throw ''
  }
  if (status == 502) {
    Toast('服务器异常，请稍后再试')
  }
  if (status == 500) {
    // loading!.close();
    Toast('请求失败，请稍后再试')
    throw '请求异常,请重试'
  }
  return response.json()
}

/**
 * 请求内容响应处理
 * @param  {Response} response  // 转为json后的响应数据
 * @return {(value: Response) => Response | PromiseLike<Response>}
 */

function successHandle(url, resolve, reject) {
  // TODO:这等有时间看一下这个loading.close放在哪合适
  // loading!.close();
  return function (value) {
    // loading!.close();
    // console.log(`${url} -> `, value)
    const { result, dataobject, dataList, dataList1, resultNote } = value
    if (result === '0' || result === '1') {
      return resolve(value)
    }
    // if (result === 403) { //  return reject(resultNote) // } // ElMessage({ //  type: 'error', //  message: resultNote // });
    return reject(resultNote)
  }
}

/**
 * 请求异常处理   暂且这样写 后面碰到的错误类型多了 可以单独拉出去一个函数 用switch 来根据错误类型来判断然后来返回对应的提示
 * 这里留了一个问题 因为超时限制是我手动把请求撤回了  而 fetchRace 方法也是我手动把响应速度慢的请求撤回了 所以这里error错误均为 The user aborted a request
 * 目前来说 fetchrace 使用的场景较少 所以暂且不来区分这个东西
 * @param  {Response} response  // 请求异常信息
 * @return {(value: Response) => Response | PromiseLike<string>}
 */
function errorHandle(url, reject) {
  return function (error) {
    // loading!.close();
    // console.log(`url -> error:`, error)
    if (
      error &&
      error.toString &&
      error.toString().indexOf('The user aborted a request') > -1
    ) {
      // ElMessage({
      //     type: 'error',
      //     message: '请求超时,请重试'
      // });
      return
    }
    if (!error) {
      return
    }
    // ElMessage({
    //     type: 'error',
    //     message: error
    // });
    return reject(error)
  }
}

/**
 * 上面是基础api配置
 * -----------------------------------------------------------------------------------------------------------------------------------
 * 下面是导出用来使用的api
 */

/**
 * HTTP GET 请求
 * @param  {string} url
 * @param  {object} header
 * @return {Promise}
 */
// interface getParams {
//     url: string,
//     header?: { [propName: string]: any },
//     query?: { [propName: string]: any }
// }

export function get(param) {
  const { url, header, query } = param
  return createFetch({
    method: 'GET',
    url: url + createQuery(url, fliterSpace(query)),
    header,
  })
}

/**
 * HTTP POST
 * @param  {string} url   请求地址
 * @param  {object} header   额外header
 * @param  {object} body   请求体
 * @return {Promise}
 */
// interface postParams {
//     url: string,
//     header?: {},
//     body?: any,
//     query?: any
// }

export function post(param) {
  const { url, header, body = {}, query } = param
  const { companyCode, uid } = store.getters['user/getUserInfo']
  return createFetch({
    method: 'POST',
    url: url + createQuery(url, query),
    header,
    body: { companyCode, uid, ...body },
  })
}

/**
 * HTTP PUT
 * @param  {string} url   请求地址
 * @param  {object} header   额外header
 * @param  {object | string} body   请求体
 * @return {Promise}
 */
// interface putParams {
//     url: string,
//     header?: {},
//     body?: any,
// }

export function put(param) {
  const { url, header, body = {} } = param
  return createFetch({
    method: 'PUT',
    url,
    header,
    body: JSON.stringify(body ?? ''),
  })
}

/**
 * HTTP DELETE
 * @param  {string} url   请求地址
 * @param  {object} header   额外header
 * @return {Promise}
 */
// interface putParams {
//     url: string,
//     header?: {},
// }

export function del(param) {
  const { url, header } = param
  return createFetch({
    method: 'DELETE',
    url,
    header,
  })
}

/**
 * HTTP POST(请求头为文件形式的上传)
 * @param  {string} url   请求地址
 * @param  {object} header   额外header
 * @param  {object} body   请求体
 * @return {Promise}
 */
// interface postParams {
//     url: string,
//     header?: {},
//     body?: any,
//     query?: any
// }

export function upLoad(param) {
  const { url, header, body = {}, query } = param
  return createFetch(
    {
      method: 'POST',
      url: url + createQuery(url, query),
      header,
      body,
      upLoad: true,
    },
    downLoadFileOvertime
  )
}

/**
 * 下载文件流
 */
export function download(param) {
  const { url, header, query = {} } = param
  // loading = ElLoading.service({
  //     lock: false,
  //     text: 'loading....',
  //     spinner: 'el-icon-loading',
  //     background: 'rgba(0, 0, 0, 0.7)'
  // });

  const controller = new AbortController()
  const signal = controller.signal

  return Promise.race([
    new Promise((resolve, reject) => {
      fetch(HOST + url + createQuery(url, fliterSpace(query)), {
        ...defaultParams,
        headers: {
          ...initHeader(url),
          ...header,
        },
        method: 'GET',
        signal,
      })
        .then((res) => {
          if (dsq) clearTimeout(dsq)
          console.log('我看下到这res是啥样的', res)
          return res.blob()
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(
          () => {}
          // loading!.close()
        ) // 这一行打开 则不管请求成功与否 加载效果都会被关闭  暂且关上
    }),
    new Promise(
      () => (dsq = setTimeout(() => controller.abort(), downLoadFileOvertime))
    ),
  ])
}

/**
 * 下载文件流
 */
export function postDownload(param) {
  const { url, header, body = {} } = param
  // loading = ElLoading.service({
  //     lock: false,
  //     text: 'loading....',
  //     spinner: 'el-icon-loading',
  //     background: 'rgba(0, 0, 0, 0.7)'
  // });

  const controller = new AbortController()
  const signal = controller.signal

  return Promise.race([
    new Promise((resolve, reject) => {
      fetch(HOST + url, {
        ...defaultParams,
        headers: {
          ...initHeader(url),
          ...header,
        },
        method: 'POST',
        body: JSON.stringify(body),
        signal,
      })
        .then((res) => {
          if (dsq) clearTimeout(dsq)
          console.log('我看下到这res是啥样的', res)
          return res.blob()
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(
          () => {}
          // loading!.close()
        ) // 这一行打开 则不管请求成功与否 加载效果都会被关闭  暂且关上
    }),
    new Promise(
      () => (dsq = setTimeout(() => controller.abort(), downLoadFileOvertime))
    ),
  ])
}

/**
 * Promise all 封装
 * @param  {Promise<any>[]} param
 * @return {Promise<any>}
 */
export function fetchAll(param) {
  return Promise.all(param)
}

/**
 * Promise race 封装  此处未处理请求中止问题(即其余请求会继续发送) 其实此处也无需去管 中止问题 因为 race 只会返回最先完成的请求 其余请求并不进入 .then
 * @param  {Promise<any>[]} param
 * @return {Promise<any>}
 */
export function fetchRace(param) {
  return Promise.race(param).then((res) => {
    return res
  })
}

/**
 * 上面是导出用来使用的api
 * -----------------------------------------------------------------------------------------------------------------------------------
 * 下面是一些特异性使用的方法
 */

// interface postParams {
//     url: string,
//     header?: {},
//     body?: any,
//     query?: any
// }

/**
 * 过滤query和body参数前后空格的方法
 * @param  {} param
 * @return {Promise<any>}
 */

function fliterSpace(params) {
  const _params = {
    ...(params ?? {}),
  }
  Object.keys(_params)?.map((v) => {
    if (typeof _params[v] === 'string') _params[v] = ('' + _params[v]).trim()
  })
  return _params
}

/**
 * 将body拼接为query的方法
 * @param  {} param
 * @return {Promise<any>}
 */
function createQuery(url, query) {
  const startSymbol = url.indexOf('?') > -1 ? '&' : '?'
  return query != null
    ? Object.entries(query).reduce(
        (p, v) =>
          v[1] || v[1] === 0
            ? p + (p ? '&' : startSymbol) + v[0] + '=' + v[1]
            : p,
        ''
      )
    : ''
}
