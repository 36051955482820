/** @format */

import { get, post } from '@/utils/fetch.js'

/**
 * 示例get
 * @param {BodyParams}  body
 */
export function companyAll(query) {
  return get({ url: '/company/all', query })
}

/**
 * 获取配置信息
 * @param {BodyParams}  body
 */
export function getJsSdk(body) {
  return post({ url: '/jsapi', body })
}
/**
 * 获取配置信息
 * @param {BodyParams}  body
 */
export function getQyWxLoginConfig(body) {
  return post({ url: '/qyWxProp', body })
}
/**
 * 根据code获取重定向链接
 * @param {BodyParams}  body
 */
export function qyWxLogin(body) {
  return post({ url: '/qyWxLogin', body })
}

/**
 * 示例post
 * @param {BodyParams}  body
 */
export function upload(body) {
  return post({ url: '/qyWxMaterial', body })
}
