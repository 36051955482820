export default {
  userInfo: {
    // companyCode: '3008',
    // codes: '2400500',
    // mySafeCheck: '4',
    // areaname: '新乡大区',
    // icon: 'https://wework.qpic.cn/wwhead/duc2TvpEgSTPk74IwG7Bs7suf3Ny4Qic4xNsS7bcDc9pSU4eglhnn5MA6BlSnNvcyx6EmtxnbPNI/0',
    // positions: '业务代表',
    // type: '1',
    // integrals3: '14000',
    // goldenIdea: '2',
    // uid: '17e7894e26e54d11959f065892b161d6',
    // departname: '新乡KA（新乡）',
    // areaid: '1',
    // myRectification: 0,
    // integrals1: '1000',
    // ysFlag: '1',
    // integrals2: '99985999',
    // name: '郭彦军',
    // departid: '1',
  },
  // addressInfo: {
  //   weather: {
  //     temperature: 20,
  //     weather: '晴',
  //     icon: undefined,
  //   },
  //   address: '郑州',
  //   longitude: undefined,
  //   latitude: undefined,
  //   marker: [],
  //   region: {},
  // },
  message: [],
  agreement: {
    service: undefined,
    privacy: undefined,
    integralRule: undefined,
  },
}
