/** @format */

export default {
	getStudyDetail1(state) {
		return state.studyDetail
	},
	getSafeInspection(state) {
		return state.safeInspection
	},
	getMark(state) {
		return state.mark
	},
}
