export default {
  messageDetail: undefined,
  rakingList: {
    list0: [],
    list1: [],
    list2: [],
    active: 'list0',
  },
  addressInfo: {
    weather: {
      temperature: 10,
      weather: '晴',
      icon: require('@/assets/icons/weather/qing.png'),
    },
    address: '郑州',
    longitude: undefined,
    latitude: undefined,
    marker: [],
    region: {},
  },
  rakingDetail: undefined,
  prizeDetail: undefined,
  areaslist: [],
  ideaDetail: undefined,
  inspectDetail: undefined,
  rectifyDetail: undefined,
  region: [],
}
