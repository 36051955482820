export default {
  setMessageDetail(state, payload) {
    if (payload) {
      state.messageDetail = payload
      return
    }
    state.messageDetail = {}
  },
  setRakingList(state, payload) {
    if (payload) {
      state.rakingList[payload.key] = payload.list
      return
    }
    state.rakingList.list0 = []
    state.rakingList.list1 = []
    state.rakingList.list2 = []
  },
  setRakingKey(state, payload) {
    if (payload) {
      state.rakingList.active = `list${payload}`
      return
    }
    state.rakingList.active = `list${0}`
  },
  setRakingDetail(state, payload) {
    if (payload) {
      state.rakingDetail = payload
      return
    }
    state.rakingDetail = undefined
  },
  setPrizeDetail(state, payload) {
    if (payload) {
      state.prizeDetail = payload
      return
    }
    state.prizeDetail = undefined
  },
  setAreaslist(state, payload) {
    if (payload) {
      state.areaslist = payload
      return
    }
    state.areaslist = undefined
  },
  setIdeaDetail(state, payload) {
    if (payload) {
      state.ideaDetail = payload
      return
    }
    state.ideaDetail = undefined
  },
  setInspectDetail(state, payload) {
    if (payload) {
      state.inspectDetail = payload
      return
    }
    state.inspectDetail = undefined
  },
  setRectifyDetail(state, payload) {
    if (payload) {
      state.rectifyDetail = payload
      return
    }
    state.rectifyDetail = undefined
  },
  setRegion(state, payload) {
    if (payload) {
      state.addressInfo.region = payload
      return
    }
    state.addressInfo.region = undefined
  },
  setWeather(state, payload) {
    if (payload) {
      state.addressInfo.weather = payload
      return
    }
    state.addressInfo.weather = undefined
  },
  setAddress(state, payload) {
    if (payload) {
      state.addressInfo.address = payload
      return
    }
    state.addressInfo.address = undefined
  },
  setMarker(state, payload) {
    if (payload) {
      state.addressInfo.marker = payload
      return
    }
    state.addressInfo.marker = undefined
  },
  setAddressInfo(state, payload) {
    if (payload) {
      Object.entries(payload).forEach((v) => (state.addressInfo[v[0]] = v[1]))
      return
    }
    state.addressInfo = {
      weather: {},
      address: undefined,
      longitude: undefined,
      latitude: undefined,
      marker: [],
      region: {},
    }
  },
  setRegionList(state, payload) {
    if (payload) {
      state.region = payload
      return
    }
    state.region = []
  },
}
