/** @format */

export default {
	setMessage(state, payload) {
		if (payload) {
			state.message = payload
			return
		}
	},
	setUserInfo(state, payload) {
		if (payload) {
			state.userInfo = payload
			return
		}
	},
	setAgreement(state, payload) {
		if (payload) {
			state.agreement[payload.key] = payload.val
			return
		}
	},
	//   setRegion(state, payload) {
	//     if (payload) {
	//       state.addressInfo.region = payload
	//       return
	//     }
	//     state.addressInfo.region = undefined
	//   },
	//   setWeather(state, payload) {
	//     if (payload) {
	//       state.addressInfo.weather = payload
	//       return
	//     }
	//     state.addressInfo.weather = undefined
	//   },
	//   setAddress(state, payload) {
	//     if (payload) {
	//       state.addressInfo.address = payload
	//       return
	//     }
	//     state.addressInfo.address = undefined
	//   },
	//   setMarker(state, payload) {
	//     if (payload) {
	//       state.addressInfo.marker = payload
	//       return
	//     }
	//     state.addressInfo.marker = undefined
	//   },
	//   setAddressInfo(state, payload) {
	//     if (payload) {
	//       Object.entries((v) => (state.addressInfo[v[0]] = v[1]))
	//       return
	//     }
	//     state.addressInfo = {
	//       weather: {},
	//       address: undefined,
	//       longitude: undefined,
	//       latitude: undefined,
	//       marker: [],
	//       region: {},
	//     }
	//   },
}
