/** @format */

import wx from 'weixin-js-sdk'
import { getJsSdk, qyWxLogin, getQyWxLoginConfig } from '@/api/wx'
export class Jweixin {
  constructor() {
    if (Jweixin.instance) {
      return Jweixin.instance
    }
    Jweixin.instance = this
    if (this.ready) {
      return
    }
  }

  instance = undefined
  ready = false
  loginUrl = undefined
  getQyWxLoginConfig() {
    console.log('先设置loginurl')
    getQyWxLoginConfig({}).then((res) => {
      this.loginUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${res.appid}&agentid=${res.agentid}&redirect_uri=${window.location.origin}&response_type=code&scope=snsapi_privateinfo#wechat_redirect`
    })
  }

  // 登录地址

  // 登录
  wxLogin() {
    console.log('先登录？')
    if (!(window.location.search && window.location.search.includes('='))) {
      if (this.loginUrl != undefined) {
        window.location.href = this.loginUrl
        console.log(this.loginUrl)
        return
      }

      return
    }
    const { code } = window.location.search
      .split('?')[1]
      .split('#')[0]
      .split('&')
      .reduce((p, v) => {
        let v1 = v.split('=')
        p[v1[0]] = v1[1] && v1[1] != 0 ? v1[1] : undefined
        return p
      }, {})
    if (!code) {
      window.location.href = this.loginUrl
      return
    }

    return new Promise((resolve, reject) => {
      qyWxLogin({
        code: code,
      })
        .then((res) => {
          console.log(res)
          if (res.result == '0') {
            resolve(res)
          }
          if (this.ready) {
            return
          }
          this.setConfig()
        })
        .catch((err) => reject(err))
    })
  }

  // 设置
  setConfig() {
    return new Promise((resolve) => {
      getJsSdk({
        url: window.location.href.split('#/')[0],
      }).then((res) => {
        wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appid, // 必填，企业微信的corpID，必须是本企业的corpID，不允许跨企业使用
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.noncestr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: [
            'chooseImage',
            'uploadImage',
            'previewImage',
            'openLocation',
            'getLocation',
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'onMenuShareAppMessage',
            'onMenuShareTimeline',
          ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        })
      })
      wx.ready(() => {
        this.ready = true
        resolve(true)
      })
    })
  }

  // 上传
  uploadImg(
    data = {
      count: 9,
      sizeType: ['compressed'],
      sourceType: ['album', 'camera'],
      defaultCameraMode: 'normal',
      isSaveToAlbum: 1,
    }
  ) {
    if (!this.ready) {
      // 这写个对话框让用户手动选择重新获取配置信息
      return
    }
    const { count, sizeType, sourceType, defaultCameraMode, isSaveToAlbum } =
      data
    return new Promise((resolve, reject) => {
      wx.chooseImage({
        count,
        sizeType,
        sourceType,
        defaultCameraMode,
        isSaveToAlbum,
        success: async (res) => {
          Promise.all(res.localIds.map((v) => this.uploadImageAsync(v)))
            .then((res) => resolve(res))
            .catch((err) => {
              console.log('上传图片到企微失败', err)
            })
        },
        fail: (error) => {
          reject(error)
        },
      })
    })
  }

  uploadImageAsync(localId) {
    return new Promise((resolve, reject) => {
      wx.uploadImage({
        localId: localId,
        isShowProgressTips: 1,
        success: (res) => {
          resolve(res.serverId)
        },
        fail: (error) => {
          reject(error)
        },
      })
    })
  }

  // 定位
  getLocation() {
    if (!this.ready) {
      // 这写个对话框让用户手动选择重新获取配置信息
      return
    }
    return new Promise((resolve, reject) => {
      wx.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          // var latitude = res.latitude // 纬度，浮点数，范围为90 ~ -90
          // var longitude = res.longitude // 经度，浮点数，范围为180 ~ -180。
          // var speed = res.speed // 速度，以米/每秒计
          // var accuracy = res.accuracy // 位置精度
          resolve(res)
        },
        fail: function (err) {
          reject(err)
        },
      })
    })
  }
}
