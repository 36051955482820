/** @format */

import {
	List,
	PullRefresh,
	Image as VanImage,
	Loading,
	Icon,
	Badge,
	Search,
	Tabbar,
	TabbarItem,
	Tag,
	NumberKeyboard,
	Popup,
	Field,
	Button,
	Picker,
	Dialog,
	Toast,
	Uploader,
	TreeSelect,
	DropdownMenu,
	DropdownItem,
	Empty,
	Overlay,
	Tab,
	Tabs,
	DatetimePicker,
	Cell,
	CellGroup,
	Calendar,
	RadioGroup,
	Radio,
	Checkbox,
	CheckboxGroup,
	Lazyload,
	NoticeBar,
	Swipe,
	SwipeItem,
} from 'vant'

const components = [
	List,
	PullRefresh,
	VanImage,
	Loading,
	Icon,
	Badge,
	Search,
	Tabbar,
	TabbarItem,
	Tag,
	NumberKeyboard,
	Popup,
	Field,
	Button,
	Picker,
	Dialog,
	Toast,
	Uploader,
	TreeSelect,
	DropdownMenu,
	DropdownItem,
	Empty,
	Overlay,
	Tab,
	Tabs,
	DatetimePicker,
	Cell,
	CellGroup,
	Calendar,
	RadioGroup,
	Radio,
	Checkbox,
	CheckboxGroup,
	Lazyload,
	NoticeBar,
	Swipe,
	SwipeItem,
]

// const plugins = [ElInfiniteScroll, ElLoading, ElMessage, ElMessageBox, ElNotification]

export { components }
