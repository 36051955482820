<!-- @format -->

<template>
  <div id="app">
    <!-- <HomeHeader /> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import { Jweixin } from '@/utils/wx'

import './style/base.css'
export default {
  data() {
    return {
      on: true,
      off: false,
    }
  },
  created() {
    let jweixin = new Jweixin()
    jweixin.setConfig()
  },
  mounted() {},
  methods: {},
  name: 'App',
}
</script>
<style lang="less">
#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.common-page {
  flex: 1;
  height: 0;
  width: 100%;
  overflow-y: auto;
}

.common-page::-webkit-scrollbar {
  display: none;
}

.van-tab {
  color: #333333 !important;
  text-align: center !important;
  font-family: 'Poppins' !important;
  font-size: 28px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

.van-tab--active {
  color: #cd0720 !important;
  text-align: center !important;
  font-family: 'Source Han Sans CN' !important;
  font-size: 28px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 28px !important;
}

.van-tabs__line {
  background-color: #06539b !important;
  height: 4px !important;
  border-radius: 2px !important;
}

.van-tabs--line .van-tabs__wrap {
  height: 96px !important;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  display: none;
  width: 750px;
  padding: 32px 32px 32px 32px;
  align-items: center;
  flex-shrink: 0;
  background-color: #3875c6;

  .icon {
    display: flex;
    width: 60px;
    height: 60px;
    padding: 12px 14px 12px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    background: #ffffff33;
    backdrop-filter: blur(20px);
    margin-right: 32px;
    font-weight: normal;
    font-size: 34px;
  }

  .n {
    color: #ffffff;
    font-family: 'Source Han Sans CN';
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
  }
}

.van-tabbar {
  height: 112px !important;
  border-radius: 40px 40px 0 0;
  overflow: hidden;

  .van-tabbar-item__text {
    text-align: center;
    font-family: 'Source Han Sans CN';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    img {
      width: 48px;
      height: 48px;
      object-fit: contain;
      display: block;
      margin-bottom: 8px;
    }
  }
}

.submitAndFixedBottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.08);
  left: 0;
  padding: 32px 32px calc(32px + env(safe-area-inset-bottom));
  background: #fff;
  .submit {
    display: flex;
    padding: 20px 100px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 200px;
    background: var(--Main-colours-Red, #cd0720);
    color: #ffffff;
    font-family: 'Source Han Sans CN';
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
}

/**
 * 适配 iphone 底部安全区（指示条，配合index.html <meta name="viewport" ... />使用
 */
@supports (bottom: env(safe-area-inset-bottom)) {
  #app {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
.van-empty {
  height: 100%;
  .van-empty__image {
    width: 212px;
    height: 200px;
    margin-bottom: 18px;
  }

  .van-empty__description {
    color: #999999;
    text-align: center;
    font-family: 'Source Han Sans CN';
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
}
</style>
