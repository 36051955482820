<!-- @format -->

<script>
import { Jweixin } from '@/utils/wx'
import { upload } from '@/api/wx'
import { ImagePreview } from 'vant'
export default {
  name: 'QZUpload',
  props: {
    onlyShow: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 20,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imgList: [],
      jweixin: new Jweixin(),
    }
  },
  created() {
    this.imgList = [...this.list]
  },
  watch: {
    list: {
      handler(newVal) {
        this.imgList = newVal
      },
      deep: true,
    },
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    async uploadImg() {
      // const userAgent = navigator.userAgent
      // const isEnterpriseWeChat = /wxwork/i.test(userAgent)
      // if (!isEnterpriseWeChat) {
      //   return this.$toast('请在企业微信环境中打开')
      // }
      // this.imgList = [...this.list, this.list[0]]
      if (this.imgList.length >= this.max) {
        return this.$toast(`最多上传${this.max}张`)
      }
      let mediaIdList = await this.jweixin.uploadImg()
      console.log('我看看数据吧', mediaIdList)
      const params = {
        res: mediaIdList,
      }
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
      })
      let request = await upload(params)
      this.$toast.clear()
      if (request.result == '0') {
        this.imgList = [
          ...this.imgList,
          ...request.dataList.map((v) => v.uploadUrl),
        ]
        this.$nextTick(() => this.emitImgList())
      }
    },

    emitImgList() {
      this.$emit('change', this.imgList)
    },
    ImagePreview(index) {
      const { imgList: images } = this
      console.log(images)
      ImagePreview({
        images,
        startPosition: index,
        closeable: true,
      })
    },
    del(item) {
      console.log('删图片', 1)
      const { imgList } = this
      let arr = JSON.parse(JSON.stringify(imgList))
      let _index = arr.findIndex((v) => v == item)
      console.log('删图片1', _index, arr, item)
      if (_index > -1) {
        arr.splice(_index, 1)
        this.imgList = arr
        this.emitImgList()
      }
    },
  },
}
</script>

<template>
  <div class="qz-upload-img-upload" v-if="!onlyShow">
    <div>
      <img @click="uploadImg" src="@/assets/icons/uploadImg.png" />
    </div>
    <div
      class="upload-item"
      @click="ImagePreview(index)"
      v-for="(item, index) in imgList"
      :key="index"
    >
      <img :src="item" />
      <div class="upload-item-del" @click.stop="del(item)">
        <img src="@/assets/icons/4.png" />
      </div>
    </div>
  </div>
  <div class="qz-upload-img" v-else>
    <van-image
      :src="item"
      v-for="(item, index) in imgList"
      :key="index"
      @click="ImagePreview(index)"
    >
      <template v-slot:loading>
        <van-loading type="spinner" size="20" />
      </template>
    </van-image>
    <div class="isMoreImage" v-if="imgList && imgList.length > 5">
      更多<br />
      + 1
    </div>
  </div>
</template>

<style lang="less" scoped>
.qz-upload-img-upload {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > div > img {
    width: 116px;
    height: 116px;
    margin-right: 16px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 16px;
    margin-bottom: 16px;
    display: block;
  }
  > div:nth-child(5n) > img {
    margin-right: 0 !important;
  }

  .upload-item {
    position: relative;
    .upload-item-del {
      position: absolute;
      right: 16px;
      top: 0;
      width: 36px;
      height: 36px;
      transform: translate(50%, -50%);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
  }
}

.qz-upload-img {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  /deep/ .van-image {
    width: 116px;
    height: 116px;
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 16px;
    margin-bottom: 16px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .isMoreImage {
    background: rgba(0, 0, 0, 0.5);
    width: 116px;
    height: 116px;
    position: absolute;
    right: 0px;
    bottom: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  }
}
</style>
