import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'
import global from './global/index.js'
import user from './user/index.js'
import clockIn from './clockIn/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    clockIn,
    global,
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      key: 'vuex',
      paths: ['user'],
    }),
    createPersistedState({
      storage: window.sessionStorage,
      key: 'global',
      paths: ['global', 'clockIn'],
    }),
  ],
})
