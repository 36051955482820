/** @format */

export default {
  setStudyDetail(state, payload) {
    if (payload) {
      state.studyDetail = payload
      return
    }
    state.studyDetail = undefined
  },
  setSafeInspection(state, payload) {
    if (payload) {
      state.safeInspection = payload
      return
    }
    state.safeInspection = undefined
  },
  setKeep(state, payload) {
    if (payload) {
      state.keep = payload
      return
    }
    state.keep = 0
  },
  setMark(state, payload) {
    if (payload) {
      state.mark = payload
      return
    }
    state.mark = {
      state: false,
      success: undefined,
    }
  },
}
